import React from "react"
import Footer from '../components/footer'
import ContactButton from '../components/contact-button'

import 'bootstrap/dist/css/bootstrap-grid.min.css';
import '../styles/main.scss';

const Layout=({ children }) => {

    return(
        <>
            <main className="main-content">
                { children }
            </main>
            <ContactButton />
            <Footer />
        </>
    )
}

export default Layout 