import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const ContactForm=({ className }) => {

    const [status, setStatus] = useState('');

    const submitForm = (ev) => {
      ev.preventDefault();
      const form = ev.target;
      const data = new FormData(form);
      const xhr = new XMLHttpRequest();
      xhr.open(form.method, form.action);
      xhr.setRequestHeader("Accept", "application/json");
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          form.reset();
          setStatus("SUCCESS");
        } else {
          setStatus("ERROR");
        }
      };
      xhr.send(data);
    }

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        <form className={`form ${className ? ' '+className : ''}`}
            onSubmit={(ev) => submitForm(ev)}
            action="https://formspree.io/mgepgjqw"
            method="POST"
        >
            <div className="formRow">
                <div className="formSet">
                    <input className="formInput" type="text" id="Name" name="name" placeholder="Name" required />
                </div>
            </div>
            <div className="formRow">
                <div className="formSet">
                    <input className="formInput" type="email" id="Email" name="email" placeholder="Email Address" required />
                </div>
            </div>
            <div className="formRow">
                <div className="formSet">
                    <input className="formInput" type="tel" id="Telephone" name="telephone" placeholder="Telephone (Optional)" />
                </div>
            </div>
            <p>I'm a:</p>
            <div className="formRow">
                <div className="formSwitch d-inline-block">
                    <input name="parent" id="ParentSwitch" type="checkbox" />
                    <label className="formLabel" htmlFor="ParentSwitch">Parent</label> 
                </div>
            </div>
            <div className="formRow">
                <div className="formSwitch d-inline-block">
                    <input name="teacher" id="TeacherSwitch" type="checkbox" />
                    <label className="formLabel" htmlFor="TeacherSwitch">Teacher</label> 
                </div>
            </div>
            <p>Please select the apps you are interested in or need assistance with:</p>
            <div className="formRow">
                <div className="formSwitch d-inline-block">
                    <input name="goread" id="ReadSwitch" type="checkbox" />
                    <label className="formLabel" htmlFor="ReadSwitch">BoomWriter UK (GoWrite)</label> 
                </div>
            </div>
            <div className="formRow">
                <div className="formSwitch d-inline-block">
                    <input name="gowrite" id="WriteSwitch" type="checkbox" />
                    <label className="formLabel" htmlFor="WriteSwitch">BoomReader (GoRead)</label> 
                </div>
            </div>
            <div className="formRow">
                <textarea className="formTextarea" name="message" id="Message" rows="5" placeholder="Add an optional message" />
            </div>
            

            <div className="formRow mt-1">
                <div className="formSwitch d-inline-block">
                    <input name="request-demo" id="DemoSwitch" type="checkbox" />
                    <label className="formLabel" htmlFor="DemoSwitch">I would like to book a demonstration</label> 
                </div>
            </div>
            <div className="formRow mt-1">
                <ReCAPTCHA
                    sitekey="6LdTiKUZAAAAAH2LmjZQ-fVgaFB5hBdhLIZWpEfZ"
                    onChange={onChange}
                />
            </div>
            <div className="formRow mt-2">
                <div className="formSet">
                    {status === "SUCCESS" ? <p>Thanks!</p> : <button className="formBtn mr-half primary-btn">Send</button>}
                    {status === "ERROR" && <p>Ooops! There was an error.</p>}
                </div>
            </div>
        </form>
    )
}