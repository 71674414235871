import React from "react";
import { Container } from "react-bootstrap";

import * as Icon from 'react-feather';
import logo from '../../images/general/logos/bw-walch-logo-reversed.png';

import { Link } from "gatsby"

const Footer=() => {
    return (

        <footer className="footer">
            <div className="mainFooter">
                <Container>
                    <ul className='footer_nav'>
                        <li><Link to="/privacy" className="footer_nav_link">Privacy Policy</Link></li>
                        <li><Link to="/terms" className="footer_nav_link">Terms &amp; Conditions</Link></li>
                        <li><Link to="/cookies" className="footer_nav_link">Cookies</Link></li>
                    </ul>
                    <div className="footer_social">
                        <a href="https://twitter.com/goreaduk" className="smLink" title="Follow us on Twitter">
                            <Icon.Twitter alt="Twitter" className="icn" />
                        </a>
                        <a href="https://www.facebook.com/GoApps-110524817417379" className="smLink" title="Follow us on Facebook">
                            <Icon.Facebook alt="Facebook" className="icn" />
                        </a>
                    </div>
                </Container>
            </div>
            <div className="subFooter">
              <div className="container subFooter_inner">
                <div className="subFooter_wrapper">
                  <div className='subFooter_logo'>
                    <img src={logo} alt="BW Walch" />
                  </div>
                  <div className="subFooter_info">
                    <p className="smallText">GoApps, BoomWriter and BoomReader are members of the BW Walch family</p>
                    <a className='footer_link smallText' href="https://bwwalch.com/">bwwalch.com</a>
                  </div>
                </div>
                <p className='subFooter_copyright smallText'>&copy; 2022 BW Walch</p>
              </div>
            </div>
        </footer>
)}

export default Footer
