import React, { useState } from "react";
import { MessageCircle } from 'react-feather';

import { Modal } from '../modal';
import { ContactForm } from '../forms'

const ContactButton=() => {

    const [contactModal, setContactModal] = useState(false);

    return (
        <>
        <button onClick={ () => setContactModal(true) } className="d-flex align-items-center justify-content-center headingSmall mb-0 contactBtn">
            <MessageCircle size="32" className="contactBtnIcon" />
        </button>

        { contactModal && (
            <Modal closeModal={() => setContactModal(false)}>
                <div className={`contactModalText px-2 py-2 reversed`}>
                    <h1 className="headingSmall mb-half">Contact Us</h1>
                    <p>For more information or support using GoApps, please contact us using the form below:</p>
                </div>
                <ContactForm className="px-2 pt-half pb-1" />
            </Modal>
        )}
        </>
    )
}

export default ContactButton